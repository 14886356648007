<template>
  <v-container>
    <v-container>
      <v-row justify="center">
        <v-col
          v-for="(item, index) in entities"
          style="pr-5"
          v-bind:key="index"
          cols="auto"
        >
          <v-card max-width="344" min-width="344" :elevation="5">
            <v-card-title>{{ caption(item) }}</v-card-title>
            <v-card-text>
              <v-row align="center" class="mx-0" v-if="enableRating">
                <v-rating
                  :value="4.5"
                  color="green"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>
                <div class="grey--text ml-4">4.5 (413)</div>
                <div class="ml-4" align="right">
                  <v-chip class="ma-2" color="success" outlined>Работает</v-chip>
                </div>
              </v-row>
              <v-row>
                <v-list :dense="true">
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(propitem, index) in properties"
                      v-bind:key="item._id + index"
                    >
                      <v-list-item-icon> 
                        <v-icon dense>{{ propitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-4">
                        <v-list-item-title>
                          {{ (item[propitem.field] || "н/д").substring(0,40) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="primary accent-4"
                :to="{ name: urlOpenItemEndpoint, params: { id: item._id } }"
                >Открыть...</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- New item form -->
        <v-col style="pr-5" cols="auto">
          <v-form
            v-model="valid"
            @submit.prevent="addNewItem"
            v-if="isNewItemMode"
          >
            <v-card max-width="344" min-width="355" :elevation="5">
              <v-card-title>
                <v-text-field
                  v-model="newItem.title"
                  :rules="[v => !!v || 'Наименование']"
                  label="Наименование"
                  required
                ></v-text-field>
              </v-card-title>
              <!--v-card-text>
                <p class="display-1 text--primary"></p>
                <p>Created at</p>
                <div class="text--primary">Status is</div>
              </v-card-text-->
              <v-card-actions>
                <v-btn color="primary" :disabled="!valid" type="submit"
                  >Добавить {{ textOneItem }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
          <v-card max-width="344" min-width="355" :elevation="5" v-else>
            <v-card-title>{{ textNewItem }}</v-card-title>
            <v-card-text style="height: 25px; position: relative">
              <v-fab-transition>
                <v-btn
                  v-show="!isNewItemMode"
                  color="primary"
                  dark
                  absolute
                  bottom
                  right
                  fab
                  @click="isNewItemMode = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { VListItemIcon } from "vuetify"
export default {
  components: {
    VListItemIcon
  },
  props: {
    name: {
      type: String
    },
    entities: {
      type: Array
    },
    enableRating: {
      type: Boolean,
      default: false
    },
    // [{icon: "mdi-...", field: "<name field in item of entity to be shown"},{...}]
    properties: {
      type: Array
    },
    // calucalating field
    caption: {
      type: Function
    },
    // caption for one item
    textOneItem: {
      type: String,
      default: ""
    },
    // caption for new item
    textNewItem: {
      type: String,
      default: ""
    },
    // caption for many item
    textManyItems: {
      type: String,
      default: ""
    },
    // endpoint of service to fetch a list and add new item
    urlEndpoint: {
      type: String
    },
    // endpoint of service open an item
    urlOpenItemEndpoint: {
      type: String
    }
  },
  name: "list-root-entities",
  data: () => ({
    isNewItemMode: false,
    newItem: {
      title: ""
    },
    valid: false
  }),
  computed: {
    ratingPresented___() {
      return this.rating;
    }
  },
  methods: {
    emit(eventName, value) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    addNewItem() {
      // eslint-disable-next-line no-console
      console.log("addNewItem " + this.newItem);
      // run event
      this.emit("onNewItemSubmit", this.newItem).then(() => {
        // eslint-disable-next-line no-console
        console.log("switch off edit mode");
        this.isNewItemMode = false;
      });
    }
  },
  mounted___() {
    fetch(process.env.VUE_APP_BASE_API_URL + "/" + this.urlEndpoint)
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log(resp_json);

        this.entities = resp_json;
      });
  }
};
</script>

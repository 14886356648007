<template>
  <v-row align="center" justify="center">
    <v-col cols="10">
      <list-root-entities
        :entities="entities"
        :caption="caption"
        :enableRating="false"
        :properties="fields"
        textNewItem="Новая потребность"
        textOneItem="потребность"
        textManyItems="потребности"
        urlOpenItemEndpoint="employeeneed"
        v-on:onNewItemSubmit="onAddNewItemSubmit"
      />
    </v-col>
  </v-row>
</template>

<script>
import ListRootEntities from "@/components/ListRootEntities.vue";

export default {
  components: {
    ListRootEntities
  },
  data: () => ({
    entities: [],
    fields: [
      /*{ icon: "mdi-clock", field: "dateStart" },
      { icon: "mdi-clock", field: "dateFinish" },*/
      { icon: "mdi-briefcase", field: "project" },
      { icon: "mdi-chart-bar", field: "level" },
      { icon: "mdi-multiplication", field: "specialization" },
      { icon: "mdi-map-marker", field: "location" },
      { icon: "mdi-google-circles-extended", field: "quantity" }      
    ]
  }),
  methods: {
    caption(item) {
      return item.title;
    },
    onAddNewItemSubmit(contract) {
      // eslint-disable-next-line no-console
      console.log("onAddNewItemSubmit + " + contract);
      fetch(process.env.VUE_APP_BASE_API_URL + "/employeeneeds", {
        method: "POST",
        body: JSON.stringify(contract),
        headers: {
          "content-type": "application/json"
        }
      })
        .then(resp => resp.json())
        .then(result => {
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.entities.push(result);
          }
          // clear
          //this.newEmployeeLastnameFirstname = "";
          //this.newItemMode = false;
        });
    }
  },
  mounted() {
    fetch(process.env.VUE_APP_BASE_API_URL + "/employeeneeds")
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log(resp_json);
        // save contracts
        this.entities = resp_json;
      });
  }
};
</script>
